//import type { ItemModel, DataItemModel, RecordSourceOptions, RecordSourceFieldType } from 'o365.modules.DataObject.Types.ts';

import DataItem from 'o365.modules.DataObject.Item.ts';
import FileUtils from 'o365.modules.fileUtils.ts';
import {getDataObjectById} from 'o365.vue.ts';


const { isMedia,safeEscapeFileName } = FileUtils;


//import { DataObject } from 'o365.modules.DataObject.ts';

declare module 'o365.modules.DataObject.ts' {
    interface DataItem<T> {
        getLink: Function;
    }
}

Object.defineProperties(DataItem.prototype, {
    'file': {
        get() {
            if (this.file == null) {
                this.file = new File(this);
                
            }
            return this.file;
        }
    }
});


export default class File<T extends DataItem = DataItem> {
    private _dataItem:T;
    
    constructor(pDataItem: T){
        this._dataItem = pDataItem;
    }
    getLink(isDownload = false, originalFile = false){
         // if (props.offline) {
            //return getOfflineLink(row, isDownload, originalFile);
        //}

       // const { primKey, FileName } = this._dataItem;

        const FileName = this._dataItem.item["FileName"];
        const PrimKey = this._dataItem.item["PrimKey"];

        let urlBase = '/nt/api';

        if (isMedia(FileName) || originalFile) {
            urlBase = '/nt/api/file';
        }
        const dataObject = getDataObjectById(this._dataItem.dataObjectId)
        const viewName = dataObject.viewName;

        let fileOrigin = '';

        if (isMedia(FileName)) {
            fileOrigin = isDownload ? 'download' : 'view';
        } else {
            if (isDownload) {
                fileOrigin = originalFile ? 'download' : 'download-pdf';
            } else {
                fileOrigin = 'view-pdf';
            }
        }

        return [urlBase, fileOrigin, viewName, PrimKey, safeEscapeFileName(FileName)].join('/');
    }
  
}




